/* eslint-disable guard-for-in */
import { Component, Vue, Watch } from 'vue-property-decorator';
import axios, { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { identifyHostname } from '@/support/Client';
import { Token } from '@/support/Token';
import MiFileUpload from '@/components/mi-file-upload/MiFileUpload';
import { ReportType } from '@/models/ReportType';

@Component<ExcelUploader>({})
export default class ExcelUploader extends Vue {
  public $pageTitle = 'Excel uploader';

  protected isLoading = true;

  protected reportType = '';

  public oAuthServer: string = identifyHostname() || '';

  protected isUploadingFile = false;

  protected uploadedFile = '';

  protected uploaded = false;

  protected acceptedFileTypes: string[] = [
    'application/vnd.ms-excel',
    'application/vnd.ms-excel',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
    'application/vnd.ms-excel.sheet.macroEnabled.12',
    'application/vnd.ms-excel.template.macroEnabled.12',
    'application/vnd.ms-excel.addin.macroEnabled.12',
    'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
  ];

  protected fileUploaded = false;

  protected fileResults: any[] = [];

  protected fileLogs: string[] = [];

  protected fileResultsMap: any = {
    row_count: {
      label: 'Aantal regels in bestand',
    },
    success: {
      label: 'Succesvol ingelezen',
    },
    failed_referentie: {
      label: 'Ongeldige referentie',
    },
    failed_report: {
      label: 'Geen rapport gevonden',
    },
    failed_dossier_status: {
      label: 'Geen rapport gevonden',
    },
    failed_existing_smr: {
      label: 'Reeds SMR rapport',
    },
    failed_organization: {
      label: 'Ongeldig expertise bureau',
    },
    failed_expert: {
      label: 'Deskundige niet gevonden',
    },
    failed_status: {
      label: 'Overgeslagen obv status',
    },
  };

  protected reportTypes: ReportTypeObject[] = [];

  public mounted() {
    this.emitBreadcrumb();
    this.getReportTypes();
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: `Import-tool ${this.$route.meta.type}` },
        ],
      });
  }

  getReportTypes() {
    new ReportType()
      .all()
      .then((reportTypes: ReportTypeObject[]) => {
        this.reportTypes = reportTypes;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected handleItemDropped(file: File) {
    if (! file) {
      return;
    }

    this.postFile(file);
  }

  protected getAxiosClient() {
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    if (Token.get('access')) {
      (headers as any).Authorization = `Bearer ${Token.get('access')}`;
    }
    const axiosClient = axios.create({
      baseURL: this.oAuthServer,
      headers,
    });
    return axiosClient;
  }

  protected postFile(file: File | string) {
    this.fileLogs = [];
    this.fileResults = [];
    this.isLoading = true;
    const formData = new FormData();

    formData.append('file', file);
    const client = this.getAxiosClient();
    const fileUploadComponent = this.$refs.fileUpload as MiFileUpload;

    client
      .post(`v1/tools/${this.$route.meta.type}/update`, formData)
      .then((response: any) => {
        fileUploadComponent.uploaded();
        this.mapResponse(response.data);
        this.fileUploaded = true;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        fileUploadComponent.uploaded();
        this.fileUploaded = false;
        this.fileLogs = [];
        this.fileResults = [];
      });
  }

  protected mapResponse(response: any) {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in response) {
      if (this.fileResultsMap[key]) {
        const fileResultsObject = {
          label: this.fileResultsMap[key].label,
          value: response[key],
        };
        this.fileResults.push(fileResultsObject);
      }

      if (key === 'logs') {
        this.fileLogs = response.logs;
      }
    }
  }

  @Watch('reportType')
  protected reportTypeChanged() {
    if (this.reportType.length) {
      this.isUploadingFile = true;
    } else {
      this.isUploadingFile = false;
    }
  }

  @Watch('$route', { deep: true })
  public routeChanged(to: any, from: any) {
    this.emitBreadcrumb();
  }
}

interface ReportTypeObject {
  created_at?: string;
  links?: string[];
  name?: string;
  updated_at?: string;
  uuid?: string;
}
